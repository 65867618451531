import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { faArchive, faCheck, faPaperPlane, faEye } from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-reportes',
  templateUrl: './reportes.component.html',
  styleUrls: ['./reportes.component.css']
})
export class ReportesComponent implements OnInit {
  /*Icons*/
  faEye = faEye;
  faCheck = faCheck;
  faPaperPlane = faPaperPlane;
  faArchive = faArchive;

  formularioModo: string = 'lista';
  ordenes = [];
  productos = [];
  noOrden: string = '';
  // pruebaOrden = [
  //   {
  //     orden: '123124',
  //     fecha: '31/08/2021',
  //     cliente: 'Mauricio',
  //     total: 50000,
  //     envio: 5000
  //   }
  // ]

  constructor(private db: AngularFirestore) { }

  ngOnInit(): void {
    this.loadTable();
  }

  public loadTable(){
    this.db.collection('Ordenes', ref => ref.where('Estado', '==', 'Finalizado')).get()
    .subscribe(ordenes => {
      ordenes.docs.forEach(orden => {
        this.ordenes.push({
          data: orden.data(),
          id: orden.id
        });
      });
      this.ordenes.forEach(orden => {
        orden.data.Productos.forEach(producto => {
          producto.Orden = orden.data.NumeroCompra;
          producto.FechaCreacion = orden.data.FechaCreacion;
          this.productos.push(producto);
        })
      })
      
    })
  }

  public openOrder(order){
    this.productos = [...order.Productos];
    this.noOrden = String(order.NumeroCompra);
    this.formularioModo = 'productos'
    
  }
  public closeForm(){
    this.formularioModo = 'lista';
  }

  public exportCSV(){
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Liquidacion productos');
    XLSX.writeFile(wb, `Reporte de orden: ${this.noOrden}.xlsx`);
  }

}
