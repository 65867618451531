export class Expositores {  
    public Apellidos: any;  
    public Ciudad: any;
    public Codigo: any;
    public Descripcion: any;
    public Direccion: any;
    public Edad: any;
    public Email: any;
    public Etiqueta1: any;
    public Etiqueta2: any;
    public Etiqueta3: any;
    public Etiqueta4: any;
    public Etiqueta5: any;
    public Etiqueta6: any;
    public FacebookUrl: any;
    public FechaNacimiento: any;
    public Identificacion: any;
    public ImgUrl: any;
    public InstagramUrl: any;
    public Nombres: any;
    public Telefono: any;
    public TipoIdentificacion: any;
    public Condicion: any;
    public Sexo: any;
    public Barrio: any;
    public TelefonoFijo: any;
    public TelefonoMovil: any;
    public LineaProducto1: any;
    public LineaProducto2: any;
    public LineaProducto3: any;
    public LineaProducto4: any;
    public LineaProducto5: any;
    public LineaProducto6: any;
    public LugarNacimiento: any;
    public EstadoCivil: any;
    public NumeroHijos: any;
    public PersonasCargo: any;
    public EstratoSocioeconomico: any;
    public GrupoEtnico: any;
    public SectorSocial: any;
    public FormacionAcademica: any;
    public Nit: any;
    public TipoVivienda: any;
    public UltimoTituloObtenido: any;
    public Localidad: any;
    public CamaraComercio: any;
    public IngresosMensualesTaller: any; 
    public PaginaWeb: any;  
    public AnoCreacionTaller: any;
    public AnoIngresoMercado: any;
    public NombreTaller: any;
    public PersonasVinculadasTaller: any;
    public UbicacionTaller: any;
    public EPS: any;
    public TipoSangre: any;
    public NombreContactoEmergencia: any;
    public TelefonoContactoEmergencia: any;
    public VinculadoSalud: any;
    public RegimenSalud: any;
  }  