import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class PagosService {

  constructor(private db: AngularFirestore) { }

  getPagos():Observable<any[]> {
    return this.db.collection('Pagos').snapshotChanges();
  }
}
