import { Component, OnInit, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { PagosService } from 'src/app/services/pagos.service';
import * as XLSX from 'xlsx'; 
import { AngularFirestore } from '@angular/fire/firestore';
import { MensajesService } from 'src/app/services/mensajes.service';

@Component({
  selector: 'app-mapa',
  templateUrl: './mapa.component.html',
  styleUrls: ['./mapa.component.css']
})
export class MapaComponent implements OnInit {
  @ViewChild('agGrid',{ static: true }) agGrid: AgGridAngular;
  latitude: number;
  longitude: number;
  zoom:number;
  rowData=[];
  markers:any[]=[];
  
  
  columnDefs = [
    {headerName: 'Numero', field: 'Numero', sortable: true, filter: true},   
    {headerName: 'Nombre', field: 'Nombre', sortable: true, filter: true},
    {headerName: 'Descripcion', field: 'Descripcion', sortable: true, filter: true},
    {headerName: 'Zona', field: 'Zona', sortable: true, filter: true},
    {headerName: 'Color', field: 'Color', sortable: true, filter: true},
    {headerName: 'Estado', field: 'Estado', sortable: true, filter: true},
    {headerName: 'ImgUrl', field: 'ImgUrl', sortable: true, filter: true},
    {headerName: 'Latitud', field: 'Latitud', sortable: true, filter: true},
    {headerName: 'Longitud', field: 'Longitud', sortable: true, filter: true},
  ];

  
  constructor(private db: AngularFirestore,private msj: MensajesService) { 

  }

  ngOnInit() {
    this.setCurrentLocation();
    this.loadTable();
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        //this.latitude = position.coords.latitude;
        //this.longitude = position.coords.longitude;
        this.latitude = 4.6944901;
        this.longitude = -74.0317065;
        this.zoom = 19;
      });
    }
  }
  
  loadTable(){
    let rowData=[];  
    this.db.collection('Stands').get().subscribe((Stands:any) => {
      Stands.docs.forEach((item) => {
        let mark=item.data();
        let marker={
        lat:mark.Latitud,
        lng:mark.Longitud,
        Numero:mark.Numero,
        Nombre:mark.Nombre,
        Descripcion:mark.Descripcion,
        Zona:mark.Zona,
        ImgUrl:mark.ImgUrl,
      };
        this.markers.push(marker);
        rowData.push(item.data());
      });
      console.log(this.markers);
      this.rowData=rowData;
        
    });
  }

  getSelectedRows() {
    const selectedNodes = this.agGrid.api.getSelectedNodes();
    const selectedData = selectedNodes.map( node => node.data );
    const selectedDataStringPresentation = selectedData.map( node => node.Numero + ' ' + node.Nombre+ ' ' + node.Descripcion+ ' ' + node.Color+ ' ' + node.Estado+ ' ' + node.ImgUrl+ ' ' + node.Latitud+ ' ' + node.Longitud).join(', ');
    alert(`Selected nodes: ${selectedDataStringPresentation}`);
  }

  public exportarExcel(): void 
  {
       const ws: XLSX.WorkSheet =XLSX.utils.json_to_sheet(this.rowData);
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Mapa');
       XLSX.writeFile(wb, "mapa.xlsx");			
  }

}
