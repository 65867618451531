import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MensajesService } from 'src/app/services/mensajes.service';
import { ActivatedRoute, Router } from '@angular/router';
import { faPencilAlt, faTrash, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-stands',
  templateUrl: './stands.component.html',
  styleUrls: ['./stands.component.css']
})

export class StandsComponent implements  OnInit {
  /*Icons*/
  faPencilAlt = faPencilAlt;
  faTrash = faTrash;
  faCross = faTimes;
  faCheck = faCheck;

  formularioStand: FormGroup;
  ImgUrl:string="";
  user:string="";
  expositor:string=""; 
  role:string="";
  arrayStands:String[]=[];
  porcentajeSubida=0;
  StandForm=false;
  editStandId="";

  expositores = []
  
  constructor(private formBuilder: FormBuilder,private storage: AngularFireStorage,private db: AngularFirestore,private msj: MensajesService,private activeRoute: ActivatedRoute) { 
    this.user=sessionStorage.getItem('User');
    this.role=sessionStorage.getItem('Role');
  }

  ngOnInit() {
    this.validate();
    this.loadTable();
    this.loadExpositores();
  }

  public loadTable(){
    this.arrayStands=[];
    this.db.collection('Stands').get().subscribe((Stands:any) => {
      Stands.docs.forEach((item) => {
        const Stand = item.data();
        Stand.id=item.id;
        this.arrayStands.push(Stand); 
      });
    });
  }

  loadExpositores(){
    this.db.collection('Expositores').get().subscribe((expositores) => {
      expositores.docs.forEach(expositor => {
        this.expositores.push(expositor.data())
      })
    })
  }

  validate(){
    this.formularioStand = this.formBuilder.group({
      Numero: ['', Validators.required],
      Nombre: ['', Validators.required],
      Descripcion: ['',Validators.required],
      Zona: ['',Validators.required],
      Color: ['', Validators.required],
      Estado: ['', Validators.required],
      FechaCreacion: [''],      
      ImgUrl: ['',Validators.required],
      Latitud: ['',Validators.required],
      Longitud: ['',Validators.required],
      CodigoExpositor: ['']
    });
  }

  uploadImage(event)
  {
    if (event.target.files.length > 0)
    { 
        let nombre = new Date().getTime().toString();
        let archivo = event.target.files[0];
        if (archivo.size < 512000)
        {
          let extension = archivo.name.toString().substring(archivo.name.toString().lastIndexOf('.'));
          let ruta = 'Stands/' + nombre + extension;
          const referencia = this.storage.ref(ruta);
          const tarea = referencia.put(archivo);
          tarea.then((objeto) => {
            referencia.getDownloadURL().subscribe((url) =>{
              this.ImgUrl = url;
            });
          });
          tarea.percentageChanges().subscribe((porcentaje) => {
            this.porcentajeSubida = parseInt(porcentaje.toString());
          });
        }
        else
        {
          this.msj.mensajeError('Agregar', 'La imagen excede 512 KB');      
        }
    }
  }

  addStand()
  {
    this.formularioStand.value.ImgUrl = this.ImgUrl;
    this.formularioStand.value.FechaCreacion = new Date();
    this.formularioStand.value.UsuarioCreacion = this.user;
    this.db.collection('Stands').add(this.formularioStand.value).then((item) => {
      this.msj.mensajeCorrecto('Agregar', 'Stand agregado correctamente');
      this.ImgUrl="";
      this.porcentajeSubida=0;
      this.formularioStand.reset();
      this.loadTable();
    });
  }

  editStand()
  {
    this.db.collection('Stands').get().subscribe((Stands:any) => {
      Stands.docs.forEach((item) => {
        const Stand = item.data();
        if(item.id==this.editStandId)
        {
            Stand.Numero=this.formularioStand.value.Numero;
            Stand.Nombre=this.formularioStand.value.Nombre,
            Stand.Descripcion=this.formularioStand.value.Descripcion;
            Stand.Zona=this.formularioStand.value.Zona;
            Stand.Color=this.formularioStand.value.Color;
            Stand.Estado=this.formularioStand.value.Estado;
            Stand.FechaCreacion=this.formularioStand.value.FechaCreacion;
            Stand.ImgUrl=this.ImgUrl;
            Stand.Latitud=this.formularioStand.value.Latitud;
            Stand.Longitud=this.formularioStand.value.Longitud;
           this.db.doc('Stands/' + item.id).update(Stand).then((resultado) => {
            this.msj.mensajeCorrecto('Editado', 'Stand actualizado correctamente');
            this.loadTable();
            }).catch(error => {
              this.msj.mensajeError('Error', 'Se ha producido un error al editar el elemento');
            });
        }
      });
    });    
  }

  enableStand(value)
  {
    this.db.collection('Stands').get().subscribe((Stands:any) => {
      Stands.docs.forEach((item) => {
        const Stand = item.data();
        if(item.id==value)
        {
          Stand.Estado="ACTIVO";
          this.db.doc('Stands/' + item.id).update(Stand).then((resultado) => {
            this.msj.mensajeCorrecto('Editado', 'Stand inactivado correctamente');
            this.loadTable();
            }).catch(error => {
              this.msj.mensajeError('Error', 'Se ha producido un error al editar el Expositor');
            });
        }
      });
    });    
  }

  disableStand(value)
  {
    this.db.collection('Stands').get().subscribe((Stands:any) => {
      Stands.docs.forEach((item) => {
        const Stand = item.data();
        if(item.id==value)
        {
          Stand.Estado="INACTIVO";
          this.db.doc('Stands/' + item.id).update(Stand).then((resultado) => {
            this.msj.mensajeCorrecto('Editado', 'Stand inactivado correctamente');
            this.loadTable();
            }).catch(error => {
              this.msj.mensajeError('Error', 'Se ha producido un error al editar el Expositor');
            });
        }
      });
    });    
  }

  deleteStand(value)
  {
    this.db.collection("Stands").doc(value).delete().then((item)=> {      
      this.msj.mensajeCorrecto('Eliminar', 'Stand eliminado correctamente');
      this.loadTable();        
    });
  }

  editStandForm(value){
    this.editStandId=value;
    this.db.collection('Stands').get().subscribe((Stands:any) => {
      Stands.docs.forEach((item) => {
        const Stand = item.data();
        if(item.id==this.editStandId)
        {
          this.ImgUrl=Stand.ImgUrl;
          this.formularioStand.patchValue({
            Numero: Stand.Numero,
            Nombre: Stand.Nombre,
            Descripcion: Stand.Descripcion,
            Zona: Stand.Zona,
            Color: Stand.Color,
            Estado: Stand.Estado,
            FechaCreacion: Stand.FechaCreacion,
            Latitud: Stand.Latitud,
            Longitud: Stand.Longitud,
            ImgUrl: Stand.ImgUrl,
          });
        }
      });
    });
    this.StandForm=true;
  }

  createStandForm(){
    this.editStandId="";
    this.StandForm=true;
  }

  closeStandForm(){
    this.editStandId="";
    this.StandForm=false;
  }

}
