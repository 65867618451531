import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { AuthenticateService } from 'src/app/services/authenticate.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  formLogin: FormGroup;
  formValid = true;
  textError = '';

  public get currentRol() {
    return this.authService.currentRole;
  }

  public get currentUser() {
    return this.authService.currentUser;
  }

  constructor(
    private formBuilder: FormBuilder, 
    private spinner: NgxSpinnerService, 
    private router: Router,
    private authService: AuthenticateService) 
  { }

  ngOnInit() {
    if(this.currentUser)
    {
      if(this.authService.currentRole=="Admin")
      {
        this.router.navigate(['expositores']);
      }
      else
      {
        this.router.navigate(['expositor']);       
      }
    }
    else
    {
      this.formLogin = this.formBuilder.group({
        email: ['', Validators.compose([Validators.required, Validators.email])],
        password: ['', Validators.required]
      });
    }
  }

  login()
  {
    if(this.formLogin.valid) 
    {
      this.formValid = true;
      this.spinner.show();
      this.authService.login(this.formLogin.value.email,this.formLogin.value.password);        
    }
    else
    {
      this.formValid = false;
      this.textError = 'Datos Incorrectos';  
    }
  }
}
