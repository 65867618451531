import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-expositoreswebpage',
  templateUrl: './expositoreswebpage.component.html',
  styleUrls: ['./expositoreswebpage.component.scss']
})
export class ExpositoreswebpageComponent implements OnInit {

  expositores: any[] = new Array<any>();
  productos: any[] = new Array<any>();
  expositor:any;
  filterValue: string ;
  id: string;
  closeResult: string;
  categoria:string;
  inicio:boolean=true;
  
  // options = [
  //   {name:'Alimentos y bocados típicos', value:'Alimentos y bocados típicos', class:""},
  //   {name:'Anticuarios/Libros', value:'Anticuarios/Libros', class:""},
  //   {name:'Bisutería', value:'Bisutería', class:""},
  //   {name:'Calzado', value:'Calzado', class:""},
  //   {name:'Cerámica', value:'Cerámica', class:""},
  //   {name:'Cosméticos y belleza', value:'Cosméticos y belleza', class:""},
  //   {name:'Forja/Escultura', value:'Forja/Escultura', class:""},
  //   {name:'Hobbies y pasatiempos', value:'Hobbies y pasatiempos', class:""},
  //   {name:'Joyería', value:'Joyería', class:""},
  //   {name:'Juguetería', value:'Juguetería', class:""},
  //   {name:'Marroquinería', value:'Marroquinería', class:""},
  //   {name:'Moda y confección', value:'Moda y confección', class:""},
  //   {name:'Pintura y artes plásticas', value:'Pintura y artes plásticas', class:""},
  //   {name:'Plantas y Vivero', value:'Plantas y Vivero', class:""},
  //   {name:'Talla en madera', value:'Talla en madera', class:""},
  //   {name:'Tejidos', value:'Tejidos', class:""},
  //   {name:'Vitrofusión', value:'Vitrofusión', class:""},
  // ]
  options = [];

  imageObject: Array<object> = [{}];
  
  constructor(public auth: AngularFireAuth,private db: AngularFirestore,private modalService: NgbModal,private route: ActivatedRoute)
  {

  }

  ngOnInit() {
    this.loadCategorias();
    this.route.queryParams.subscribe(params => {
      this.categoria = params['categoria'];
      if(this.categoria!=undefined)
      {
        this.searchCategoria(this.categoria);
      }
  });
  }

  loadCategorias(){
    this.db.collection('Categorias').get().subscribe(categorias => {
      categorias.docs.forEach(categoria => {
        this.options.push({
          name: categoria.data().name,
          value: categoria.id,
          class: ''
        })
      })
      
    })
    // this.db.collection('Parametros').doc('eisQmMJ8I8gjcEkpdyZH').get()
    // .subscribe(parametros => {
    //   parametros.data().Categorias.forEach(categoria => {
    //     this.options.push({
    //       name: categoria,
    //       value: categoria,
    //       class: ''
    //     })
    //   })
    // })
  }
  
  verInformacion(expositor){
    this.inicio=false;
    this.expositor=expositor;
    this.expositor.whatsapp="https://wa.me/57"+this.expositor.TelefonoMovil;
    this.db.collection('Productos',ref => ref.where('User', '==', expositor.Email)).get().subscribe((productos:any) => {
      this.imageObject=[];
      productos.docs.forEach((item) => {
        const producto = item.data();
        if(producto.User==expositor.Email){
          producto.id=item.id;
          this.productos.push(producto);
          this.imageObject.push({
            image: producto.ImgUrl,
            thumbImage: producto.ImgUrl,
            alt: "Descripcion: " + producto.Descripcion,
            title: producto.Nombre +" Valor: " + producto.Valor + " COP" , 
          })
        } 
      });
    });
  }

  open(content,expositor) {
    this.verInformacion(expositor);
 
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',size: 'lg'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  searchCategoria(categoria){
    
    this.options.forEach((item) => {
      if(item.value==categoria){
        item.class="active";
      }
      else
      {
        item.class="";
      }
    });
    this.expositores=[];
      this.expositores.length = 0;
       this.db.collection('Expositores',ref => ref.where('LineaProducto1', '==', categoria).where('Estado', '==', 'Activo')).get().subscribe((expositores) => {
        expositores.docs.forEach((item) => {
            let expositor = item.data();
            expositor.id = item.id;
            expositor.ref = item.ref;
            if(expositor.ImgUrl==""){
              expositor.ImgUrl="/assets/MPU.jpg"
            }
            this.expositores.push(expositor);
        }); 
      });
   }

  enviarUrlExterna(urlExterna) {
    location.href=urlExterna;   
  }
}
