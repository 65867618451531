import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { faEye } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.css']
})
export class ClientesComponent implements OnInit {

  faEye = faEye;
  formularioModo: string = 'lista';
  searchClientes = [];
  clientes = [];
  ordenes = [];
  cliente: any = {};
  productos = [];

  constructor(private db: AngularFirestore) { }

  ngOnInit(): void {
    this.loadTable();
  }

  public loadTable(){
    this.db.collection('Clientes').get()
    .subscribe(clientes => {
      clientes.docs.forEach(cliente => {
        this.clientes.push({
          data: cliente.data(),
          id: cliente.id
        });
        this.searchClientes.push({
          data: cliente.data(),
          id: cliente.id
        });
      });
    });
  }

  openClient(cliente){
    this.formularioModo="cliente";
    this.cliente = {...cliente};
    this.db.collection('Ordenes', ref => ref.where('CompradorEmail', '==', cliente.data.Email)).get()
    .subscribe(ordenes => {
      ordenes.docs.forEach(orden => {
        this.ordenes.push({
          data: orden.data(),
          id: orden.id
        });
      });
    });
  }

  closeForm(){
    this.formularioModo="lista";
  }

  viewProducts(orden){
    this.productos = [...orden.data.Productos];
    console.log(this.productos);
  }

}
