import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MensajesService } from 'src/app/services/mensajes.service';
import { ActivatedRoute, Router } from '@angular/router';
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})

export class BannerComponent implements  OnInit {

  /*Icons*/
  faPencilAlt = faPencilAlt;
  faTrash = faTrash;
  
  formularioBanner: FormGroup;
  urlImage:string="";
  user:string="";
  role:string="";
  arrayBanner:String[]=[];
  porcentajeSubida=0;
  idBanner: any;
  formularioModo: string = 'lista';
  
  constructor(private formBuilder: FormBuilder,private storage: AngularFireStorage,private db: AngularFirestore,private msj: MensajesService,private activeRoute: ActivatedRoute) { 
    this.user=sessionStorage.getItem('User');
    this.role=sessionStorage.getItem('Role');
  }

  ngOnInit() {
    this.validate();
    if(this.role=="Admin")
    {
      this.loadTable();
    }
  }

  public loadTable(){
    this.arrayBanner=[];
    this.db.collection('Banner').get().subscribe((banner:any) => {
      banner.docs.forEach((item) => {
        const banneritem = item.data();
        banneritem.id=item.id;
          this.arrayBanner.push(banneritem);
      });
    });
  }

  validate(){
    this.formularioBanner = this.formBuilder.group({
      title: [''],
      subtitle: [''],
      image: ['', Validators.required ],
      url: [''],
    });
  }

  uploadImage(event)
  {
    if (event.target.files.length > 0)
    { 
        let nombre = new Date().getTime().toString();
        let archivo = event.target.files[0];
        if (archivo.size < 512000)
        {
          let extension = archivo.name.toString().substring(archivo.name.toString().lastIndexOf('.'));
          let ruta = 'Banner/' + nombre + extension;
          const referencia = this.storage.ref(ruta);
          const tarea = referencia.put(archivo);
          tarea.then((objeto) => {
            referencia.getDownloadURL().subscribe((url) =>{
              this.urlImage = url;
            });
          });
          tarea.percentageChanges().subscribe((porcentaje) => {
            this.porcentajeSubida = parseInt(porcentaje.toString());
          });
        }
        else
        {
          this.msj.mensajeError('Agregar', 'La imagen excede 512 KB');      
        }
    }
  }

  addBanner()
  {
    console.log(this.formularioBanner.value);

    if(this.formularioModo=='lista')
    {
      if(this.arrayBanner.length<10)
        {
          this.formularioBanner.value.image = this.urlImage;
          this.db.collection('Banner').add(this.formularioBanner.value).then((item) => {
            this.msj.mensajeCorrecto('Agregar', 'Banner agregado correctamente');
            this.urlImage="";
            this.porcentajeSubida=0;
            this.formularioBanner.reset();
            this.loadTable();
          });
        }
        else
        {
          this.msj.mensajeError('Mensaje', 'Solo se pueden subir 10 elementos.'); 
        }
    }
    else
    {
      this.formularioBanner.value.image = this.urlImage;
      this.db.collection('Banner').doc(this.idBanner).update(this.formularioBanner.value)
      .then(result => {
        this.msj.mensajeCorrecto('Actualizado', 'Banner actualizado correctamente')
        this.urlImage = "";
        this.porcentajeSubida = 0;
        this.formularioBanner.reset();
        this.loadTable()
        this.formularioModo="lista"
      })
    }

    
  }

  deleteBanner(value)
  {
    this.db.collection("Banner").doc(value).delete().then((item)=> {      
      this.msj.mensajeCorrecto('Eliminar', 'Elemento eliminado correctamente');
      this.loadTable();        
    });
  }

  closeForm(){
    this.formularioModo="lista"
    this.urlImage = ""
    this.formularioBanner.reset()
  }

  openFormEdit(bannerId){
    this.idBanner = bannerId
    this.formularioModo = "edicion"
    
    this.db.doc('Banner' + '/' + bannerId).valueChanges().subscribe(banner => {
      this.setForm(banner)
    })

  }
  setForm(banner){
    this.formularioBanner.patchValue({
      title: banner.title,
      subtitle: banner.subtitle,
      url: banner.url
    })
    this.urlImage = banner.image
  }
}
