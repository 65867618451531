import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
declare var require: any;
@Injectable({
  providedIn: 'root'
})
export class DepartmentsService {

  private endpoint = 'https://raw.githubusercontent.com/marcovega/colombia-json/master/colombia.min.json';
  private departamentos = require('../../assets/data/departamentos.json')
  constructor(private httpClient: HttpClient) { }

  getDepartamentos(){
    return this.departamentos
  }
}